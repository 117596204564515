<template>
  <div>
    <div class="mt-3" />
    <b-container v-if="loading">
      <div class="text-center">
        <b-spinner label="Loading..." variant="warning"></b-spinner>
      </div>
    </b-container>
    <span v-if="!loading">
      <h5 class="text-center text-primary">Student Attendance</h5>
      <div class="mt-3" />
      <b-container fluid>
        <b-row>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 1: Select a child</strong>
            <b-list-group v-if="$auth.user['https://tpa.musihoven.nl/parentsof'].length > 0">
              <b-list-group-item v-for="item in $auth.user['https://tpa.musihoven.nl/parentsof']" :key="item.given_name" href=# button @click="onClickChild(item)" :active="form.user_id == item.user_id">
                <b-avatar variant="info" :src="item.picture" class="mr-3"></b-avatar>
                {{generateNameLabel(item.given_name, item.family_name, item.nickname, item.email)}}
              </b-list-group-item>
            </b-list-group>
            <div v-else>Please register your children. Contact admin.</div>
          </b-col>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 2: Choose date*</strong><br/>
            <b-calendar v-model="value" @context="onContext" locale="en-US" :date-info-fn="dateClass" start-weekday="1"></b-calendar><br/>
            <div class="mt-3" />
            <strong><span class="text-primary">*</span> Sessions are planned on highlighted dates</strong>
          </b-col>
          <b-col md="auto">
            <div class="mt-4" />
            <strong class="text-primary">Step 3: Input preference/attendance</strong>
            <b-row>
              <b-col>
                <div v-if="typeof(deadlineSubmissionDateTime[form.date]) !== 'undefined'">
                  <div v-if="isWithinDeadlineNow()">
                    <b-alert
                      variant="warning" 
                      show
                      >
                      Deadline submission {{deadlineSubmissionDateTime[form.date]['deadlineSubmissionDate']}}, {{deadlineSubmissionDateTime[form.date]['deadlineSubmissionTime']}} CET
                    </b-alert>
                    <b-form @submit.prevent="onSubmit" @reset.prevent>
                      <b-form-group>
                        <b-form-checkbox-group
                          :options="preferenceoptions"
                          v-model="form.preference"
                          name="roles-assignment"
                          stacked
                        ></b-form-checkbox-group>
                      </b-form-group>
                      <b-button type="submit" variant="primary">Submit</b-button>
                    </b-form>
                  </div>
                  <div v-else>
                    <b-alert
                      variant="danger" 
                      show
                      >
                      Submission deadline has passed ({{deadlineSubmissionDateTime[form.date]['deadlineSubmissionDate']}}, {{deadlineSubmissionDateTime[form.date]['deadlineSubmissionTime']}} CET)
                    </b-alert>
                    <b-form-group>
                      <b-form-checkbox-group
                        :options="preferenceoptions"
                        v-model="form.preference"
                        name="roles-assignment"
                        stacked
                        disabled
                      ></b-form-checkbox-group>
                    </b-form-group>
                  </div>
                </div>

              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>

      <div class="mt-3" />
      <b-alert 
        :show="dismissCountDown" 
        dismissible 
        variant="success" 
        @dismissed="dismissCountDown=0"
        @dismiss-count-down="countDownChanged"
        >
        {{ alertMessage }}
      </b-alert>
      <b-alert
        :show="dismissErrCountDown"
        dismissible
        variant="danger"
        @dismissed="dismissErrCountDown=0"
        @dismiss-count-down="countDownErrChanged"
      >
        {{ alertErrMessage }}
      </b-alert>
    </span>
    <div class="mt-3" />
  </div>
</template>

<script>
  import SessionService from '@/services/SessionService.js';
  import AttendanceStudentService from '@/services/AttendanceStudentService.js';

  export default {
    name: 'attendancestudent',
    title: "TPA Gembira - attendance student",
    data() {
      return {
        alertMessage: "",
        dismissSecs: 5,
        dismissCountDown: 0,
        dismissErrSecs: 5,
        dismissErrCountDown: 0,
        alertErrMessage: "",
        loading: false,
        value: '',
        context: null,
        dateWithSessionOptions: [],
        deadlineSubmissionDateTime: [],
        users: [],
        preferenceoptions: [ 
          { value: "online", text: "Online (skype/whatsapp session)"},
          { value: "classroom", text: "Offline (classroom meeting)"},
          { value: "absent", text: "Absent (not attend)"},
        ],
        form: {
          user_id: null,
          family_name: "",
          given_name: "",
          nickname: "",
          email: "",
          picture: "",
          date: "",
          preference: [],
        },
      }
    },
    created(){
      this.init();
    },
    methods: {
      isWithinDeadlineNow(){
        let now = new Date();
        let reference = new Date(this.deadlineSubmissionDateTime[this.form.date]['deadlineSubmissionDate'] + "T" + this.deadlineSubmissionDateTime[this.form.date]['deadlineSubmissionTime'] + "+01:00");
        return (now <= reference);
      },
      dateClass(ymd, date) {
        let normalizedDate = date.getFullYear()+ '-' + ("0" + (date.getMonth() + 1)).slice(-2) + '-' + ("0" + date.getDate()).slice(-2);
        if (this.dateWithSessionOptions.includes(normalizedDate)){
          return 'table-info';
        } else {
          return '';
        }
      },
      countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
      countDownErrChanged(dismissErrCountDown) {
        this.dismissErrCountDown = dismissErrCountDown
      },
      showSuccessConfirm(msg) {
        this.dismissCountDown = this.dismissSecs;
        this.alertMessage = msg;
      },
      showErrorConfirm(msg){
        this.dismissErrCountDown = this.dismissErrSecs
        this.alertErrMessage = msg;
      },
      onClickChild(item){
        this.form.user_id = item.user_id; 
        this.form.family_name = item.family_name;
        this.form.given_name = item.given_name;
        this.form.nickname = item.nickname;
        this.form.email = item.email;
        this.form.picture = item.picture;
        this.value=''; 
        this.form.preference = [];
      },
      onContext(ctx) {
        this.context = ctx;
        this.form.date = ctx.selectedYMD;
        if (ctx.selectedYMD !== null && ctx.selectedYMD !== ""){
          AttendanceStudentService.getAttendanceStudentDetails(ctx.selectedYMD, this.form.user_id)
            .then(preferenceoptions => {
              this.form.preference = typeof(preferenceoptions.preference)==='undefined' ? [] : preferenceoptions.preference;
            });
        }
      },
      async init(){
        this.loading = true;
        await Promise.all ([this.getDateWithSession()])
          .then(() => {
            this.loading = false;
          });
      },
      async getDateWithSession(){
        SessionService.getListSessions()
          .then(
            (list => {
              // users for visualization only
              for (let item of list){
                this.dateWithSessionOptions.push(item.date);
                this.deadlineSubmissionDateTime[item.date] = { 'deadlineSubmissionDate': item.deadlineSubmissionDate, 'deadlineSubmissionTime': item.deadlineSubmissionTime }
              }
            })
          );
      },
      onSubmit(){
        if (this.form.user_id == null){
          this.showErrorConfirm("Fail to submit attendance. Please select a child.")
        } else {
          if (this.form.date == ""){
            this.showErrorConfirm("Please choose the date of attendance")
          } else {
            if (this.form.preference.length == 0){
              this.showErrorConfirm("Please choose at least one preference")
            } else {
              if (this.form.preference.length > 1 && this.form.preference.includes("absent")){
                this.showErrorConfirm("Absent preference cannot be combined with any teaching preference. Please revise.")
              } else {
                if (!this.dateWithSessionOptions.includes(this.form.date)){
                  this.showErrorConfirm("Please choose only dates where TPA sessions are planned")
                } else {
                  AttendanceStudentService.upsertAttendanceStudentDetails(this.form);
                  this.dateWithSessionOptions.push(this.form.date);
                  this.showSuccessConfirm("Update attendance submitted successfully");
                }
              }
            }
          }
        } 
      },
    }
  } 
</script>

<style lang="sass" scoped>
</style>