import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getListSessions(){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/session", { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async getSessionDetails(date){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/session/" + date, { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async upsertSessionDetails(sessiondata){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'POST',
      url: "https://tpagembira.azurewebsites.net/session",
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      },
      data: sessiondata
    }

    return axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
  },
  async deleteSession(date){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'DELETE',
      url: "https://tpagembira.azurewebsites.net/session/" + date,
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      }
    }
    axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
    return "OK";
  }
}