import axios from "axios"
import { getInstance } from "../auth/index";

export default {
  async getAttendanceStudentDetails(date, user_id){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/attendancestudent/" + date + "/" + user_id, { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/attendancestudent/" + date + "/" + user_id, { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  },
  async upsertAttendanceStudentDetails(attendancestudentdata){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let options = {
      method: 'POST',
      url: "https://tpagembira.azurewebsites.net/attendancestudent",
      // url: "http://localhost:3000/attendancestudent",
      headers: {
        'content-type': 'application/json',
        'authorization': 'Bearer ' + accessToken
      },
      data: attendancestudentdata
    }

    return axios.request(options)
      .catch(function (error) {
        console.error(error);
      })
  },
  async getAttendanceOnADate(date){
    let instance = getInstance();
    const accessToken = await instance.getTokenSilently();
    let res = await axios.get("https://tpagembira.azurewebsites.net/attendancestudent/" + date, { headers: {authorization: `Bearer ${accessToken}`}});
    // let res = await axios.get("http://localhost:3000/attendancestudent/" + date, { headers: {authorization: `Bearer ${accessToken}`}});
    return res.data;
  }
}